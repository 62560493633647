import Moment from "moment";

export default {
  name: "TOperationalProcessList",
  data() {
    return {
      property: {
        listElement: {
          user: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          name: "",
          officeId: "",
          dateFrom: "",
          dateTo: "",
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      table: {
        data: {
          user: [],
        },
      },
      options: {
        cabangs: [],
      },
    };
  },
  methods: {
    Moment: Moment,
    helperGetCabangList() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "office",
          params: {
            officeName: "",
            page: 0,
          },
        })
        .then((resp) => {
          resp.data.data.content.map((index) => {
            const text = index.officeName;
            const value = index.officeId;
            this.options.cabangs.push({ text, value });
          });
        });
    },
    async findOperatonalProcess() {
      this.table.data.user = [];
      this.property.listElement.user.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          reqUrl: "operational-process",
          type: "GET_LIST_DATA",
          endPoint: "process",
          payload: {
            officeCode: this.property.filterDto.officeId,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: 0,
            size: this.property.listElement.user.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.user = resp.data.data.content;
            this.property.listElement.user.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.user.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataUser(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorUser(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.user.downloading = false;
        }, timeout + 500);
      }
    },
    async changePaginationUser(event) {
      this.table.data.user = [];
      this.property.listElement.user.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          reqUrl: "operational-process",
          type: "GET_LIST_DATA",
          endPoint: "process",
          payload: {
            officeCode: this.property.filterDto.officeId,
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            page: event - 1,
            size: this.property.listElement.user.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.user = resp.data.data.content;
            this.property.listElement.user.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataUser(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorUser(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.user.downloading = false;
        }, timeout);
      }
    },
    handleCatchErrorUser(error) {
      console.log(error.response);
      this.table.data.user = [];
      this.property.listElement.user.rows = 0;
      this.property.listElement.user.currentPage = 1;
      this.property.listElement.user.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async helperGetDataTableUser() {
      this.table.data.user = [];
      this.property.listElement.user.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          reqUrl: "operational-process",
          type: "GET_LIST_DATA",
          endPoint: "process",
          payload: {
            officeCode: "",
            dateFrom: "",
            dateTo: "",
            page: 0,
            size: this.property.listElement.user.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.user = resp.data.data.content;
            this.property.listElement.user.rows = resp.data.data.totalElements;
          } else {
            this.failedGetDataUser(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorUser(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.user.downloading = false;
        }, timeout);
      }
    },
    failedGetDataUser(resp) {
      this.table.data.user = [];
      this.property.listElement.user.rows = 0;
      this.property.listElement.user.message = resp.data.message;
    },
  },
  mounted() {
    this.helperGetCabangList();
    this.helperGetDataTableUser();
    Moment.locale("ID");
    // this.getRefenceOffice();
    // this.getIdentityOfficeCodeFromUserData();
  },
};
